body, html {
    height: 100%;

    hr {
        width: 40px;
    }

    a.btn {
        text-transform: uppercase;
        letter-spacing: .1rem;
        text-decoration: none;

        &:hover {
            background-color: $white;
            color: $color-lightblue;
        }
    }
}

.lead {
    font-style: italic;
}

.btn-lg {
    width: 100%;
}

b, strong {
    font-weight: 600;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 22px;
    margin-bottom: 11px;
    word-wrap: break-word;
    text-transform: uppercase;
    letter-spacing: .1rem;
}

section {
    text-align: center;

    padding-top: 100px;

    a {
        text-decoration: underline;
        word-wrap: break-word;
    }

    ul {
        padding-left: 20px;
    }

    &#start {
        height: 100%;
        background-image: url('/img/bg_Header.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    &#ueber-uns {

    }

    &#kontakt {
        .message.error {
            border: 1px solid $danger;
            padding: 1.5rem;
            color: $danger;
        }
    }

    &#referenzen {
        height: 1500px;

        article {
            .description {
                display: none;
            }

            .btn {
                background-color: $white;
                border-color: $gray-300;
                color: $color-lightblue;
                text-decoration: none;

                &:hover, &.active {
                    border-color: $color-lightblue;
                    color: $color-lightblue;
                }
            }

            //  margin-top: 2%;
            .grid {
                margin-top: 30px;
            }

            .grid-sizer, .grid-item {
                width: 32.5%;
            }

            .gutter-sizer {
                width: 0.7%;
            }

            .grid-item {
                // height: auto;
                margin-bottom: 10px;
                display: block;

                figure {
                    margin: 0;
                    position: relative;

                    &.hover {
                        figcaption {
                            height: 100%;
                        }
                    }

                    figcaption {
                        display: block;
                        overflow: hidden;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 0;
                        background: rgba($color-lightblue, 0.6);
                        transition-duration: 0.5s;
                        text-align: center;

                        h2, h3, h1 {
                            color: $white;
                            font-weight: normal;
                            font-size: 16px;
                            letter-spacing: .1rem;
                            margin: 0;
                            line-height: 22px;
                        }

                        h2 {
                            margin-top: 25%;
                            text-transform: uppercase;
                        }

                        h1 {
                            font-size: 18px;
                        }

                        h3 {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    #reference-filter {
        list-style: none;

        & > li {
            padding: .25rem .15rem;
            display: inline-block;
        }
    }

    &#mitarbeiter {

        figure {
            margin: 0;
            position: relative;

            &.hover {
                figcaption {
                    height: 100%;
                }
            }

            figcaption {
                display: block;
                overflow: hidden;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 0;
                background: rgba($color-lightblue, 0.6);
                transition-duration: 0.5s;
                text-align: center;

                h2, h3, h1 {
                    color: $white;
                    font-weight: normal;
                    font-size: 16px;
                    letter-spacing: .1rem;
                    margin: 0;
                    line-height: 22px;
                }

                h2 {
                    margin-top: 18rem;
                    text-transform: uppercase;
                }

                h1 {
                    font-size: $font-size-sm;
                }

                h3 {
                    font-size: 18px;
                }

                p {
                    color: $white;
                    padding: 0 1rem;
                }
            }
        }
    }

    &#map {
        padding: 0;
    }

    &#impressum {
        height: auto;
        // min-height: 670px;
        article {
            a {
                // color: $color-blue;
            }
        }
    }

    &#datenschutz {
        height: auto;
        // min-height: 670px;
        article {
            a {
                color: $color-blue;
            }
        }
    }

    #map-canvas {
        width: 100%;
        height: 500px;
        background-color: $color-lightblue;
    }
}

.blue {
    background-color: $color-lightblue;
    color: $white;

    h1, h2, h3, p.lead {
        color: $white;
    }

    hr {
        border-color: $white;
    }

    a {
        color: $white;
        text-decoration: none;

        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }

    input, textarea {
        width: 100%;
        background: none;
        padding: 10px;
        border: 1px solid $white;
        font-size: $font-size-sm;
        font-family: $font-family-serif;
        text-align: left;
        margin-bottom: 29px;
        line-height: 24px;
        color: $white;
    }

    textarea {
        height: 200px;
    }

    ::placeholder {
        color: $white;
    }

    a.btn, button.btn {
        background-color: $white;
        color: $color-lightblue;

        &:hover {
            border-color: $color-blue;
            background-color: $color-lightblue;
            color: $white;
        }
    }
}

.social-icons {
    a {
        display: inline-block;

        .social-icon {
            margin-top: .2rem;
            width: 30px;
            margin-right: .5rem;
        }
    }
}
