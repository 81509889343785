@include media-breakpoint-down(lg) {
    nav.navbar .nav-item {
        padding: 0 1rem;
    }
    .social-icons {
        padding: 0 1rem;
    }
    &#start {
        background-position-x: -200px;
        background-position-y: 70px;
    }

}

@include media-breakpoint-down(md) {
    nav.navbar .nav-item {
        padding: 0 1rem;
    }
    &#start {
        background-position-x: -300px;
        background-position-y: 70px;
    }

    section#referenzen {
        //    height: 1400px;

        article {
            margin-top: 0;

            #reference-filter {
                float: left;
                margin-top: 10px;

                li.first {
                    padding-left: 0;
                }
            }

            .grid-item figure figcaption h2 {
                margin-top: 8%;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    &#start {
        background-position-x: -415px;
        background-position-y: 70px;
    }

    section#mitarbeiter figure figcaption {
        h2 {
            margin-top: 8rem;
            font-size: $font-size-sm;
        }

        h3 {
            font-size: $font-size-sm;
        }

        p {
            font-size: $font-size-sm;
        }
    }

    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: 220px;
    }
    nav .navbar-right {
        // width: auto;
    }
    .navbar-inverse .navbar-nav > li {
        // width: auto;
    }
    section#referenzen {
        //  height: 1300px;
        article {
            #reference-filter {
                padding-left: 0;

                & > li {
                    padding: 0 15px;
                }
            }

            .grid-sizer, .grid-item {
                width: 48.5%;
            }
        }
    }

}
@media (max-width: 350px) {
    &#start {
        background-position-x: -250px;
        background-position-y: 70px;
        h1 {
            margin-top: 0;
            font-size: 1.5rem;
        }
    }
}

