@import 'bootstrap/functions';
// Define Bootstrap Variables
@import "partials/bootstrap-variables";

@import 'bootstrap/variables';
@import 'bootstrap/bootstrap';

/*-----------------------------------------------
|   User Styles
-----------------------------------------------*/
// Import own styles
@import "partials/page";
@import "partials/nav";
@import "partials/modal";

@import "partials/media";


