.references {
  a {
    color: white;
  }
  .modal-content {
    border-radius: 0;
  }
  .modal-body {
    padding: 0;
  }
  .col-xs-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .reference-title {
    padding-left: 15px;
    padding-right: 15px;
    background-color: $color-blue;
    h1, h2 {
      font-size: 14px;
      color: $white;
      strong {
        font-size: 18px;
      }
    }
    h1 {
      margin-top: 10px;
      margin-bottom: 3px;
    }
    h2 {
      margin-top: 0;
    }
  }
  .film {
    width: 880px;
    .responsive-video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .responsive-video {
      position: relative;
      padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
      padding-top: 0;
      height: 0;
      overflow: hidden;
      max-width: 880px;
      margin-bottom: -8px;
    }
  }
}


